// import Transitions from "./transitions";
import Default from "./config/default";
import { Utility } from "./config/animation";
import Locomotive, {
    locoScroll
} from "./locomotive";
import Loading, {
    Loading_enter
} from "./loading";
// import Cursor from "./cursor";

// import Header from "./header";
import Intro from "./intro";
import Universal from "./universalanim";
// import { Responsive_nomobile } from "./responsive";

function Init() {
    Default();
    Utility();
    Locomotive();

    Intro();
    Universal();
}

Loading(() => {
    Init();
});
