import gsap from "gsap";
import {
    ScrollTrigger
} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import configAnimation from "./animation";

export default function Default() {
    gsap.config({
        nullTargetWarn: false
    });

    // Config GSAP
    gsap.defaults({
        ease: configAnimation.global_ease,
        duration: configAnimation.global_duration,
        stagger: configAnimation.global_stagger,
    });

    ScrollTrigger.defaults({
        start: configAnimation.global_trigger,
        scroller: configAnimation.global_scroller,
        once: true,
    });
}