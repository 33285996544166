import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import LocomotiveScroll from 'locomotive-scroll';
import configAnimation from "./config/animation";

let elScroller = configAnimation.global_scroller;
export let locoScroll;

export default function Locomotivescroll() {
    locoScroll = new LocomotiveScroll({
        el: document.querySelector(elScroller),
        smooth: true,
        lerp: 0.06,
        getDirection: true,
        smartphone: { smooth: true },
        tablet: { smooth: true }
    });

    function scrollUpdate(instance) {
        document.documentElement.setAttribute('data-direction', instance.direction)
        ScrollTrigger.update();
    }

    // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
    locoScroll.on("scroll", (instance) => {
        scrollUpdate(instance);
    });

    ScrollTrigger.scrollerProxy(elScroller, {
        scrollTop(value) {
            return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
        }, // we don't have to define a scrollLeft because we're only scrolling vertically.
        getBoundingClientRect() {
            return {
                top: 0,
                left: 0,
                width: window.innerWidth,
                height: window.innerHeight
            };
        },
        // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
        pinType: document.querySelector(elScroller).style.transform ? "transform" : "fixed"
    });


    ScrollTrigger.addEventListener("refresh", () => locoScroll.update());

    ScrollTrigger.refresh();
}