import { map, preloadFonts } from './utils/utils';
import {
    locoScroll
} from "./locomotive";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";

export default function Intro() {
    // Get elements
    let allEl = [];
    gsap.utils.toArray(".content").forEach((el, i) => {
        allEl.push(el);
    });
    // initialize Splitting
    const splitting = Splitting();

    // keep track of the previous and current scroll values
    let scroll = { cache: 0, current: 0 };

    const tl_content = gsap.timeline({
        delay: 5,
        onComplete: ScrollRand
    });

    tl_content.from("#main", {
        opacity: 0,
    });
    

    Locoscroll_anim();

    function Locoscroll_anim() {
        // Locomotive Scroll event
        locoScroll.on('scroll', obj => {
            scroll.current = obj.scroll.y;
            const distance = scroll.current - scroll.cache;
            scroll.cache = scroll.current;
            // translation value will be mapped in the interval of [-50,50] for a scroll distance of [150,-150]
            const transformVal = {
                translateY: map(distance, 150, -150, 50, -50),
                rotate: map(distance, 150, -150, 10, -10)
            };
            // for every word from the splitting object...
            for (const [_, word] of splitting.entries()) {
                // total number of characters for this word
                const charsTotal = word.chars.length;
                // for every char from each word...
                for (const [j, char] of word.chars.entries()) {
                    // we want the middle chars to have a higher translationY value so it gives the illusion the word is bending
                    const factor = j < Math.ceil(charsTotal / 2) ? j : Math.ceil(charsTotal / 2) - Math.abs(Math.floor(charsTotal / 2) - j) - 1;
                    char.style.transform = 'translate3d(0,' + factor * transformVal.translateY + 'px,0) rotate3d(0,0,1,' + (j < charsTotal / 2 ? Math.abs(factor - charsTotal / 2) * transformVal.rotate : -1 * Math.abs(factor - charsTotal / 2) * transformVal.rotate) + 'deg)';
                }
            }

            // Also rotate the images 
            for (const key of Object.keys(obj.currentElements)) {
                if (obj.currentElements[key].el.classList.contains('content__img')) {
                    let progress = obj.currentElements[key].progress;
                    const rotateX = progress < 0.5 ? map(progress, 0, 0.5, -20, 0) : map(progress, 0.5, 1, 0, 20);
                    obj.currentElements[key].el.style.transform = `rotateX(${rotateX}deg)`
                }
            }
        });
    }

    setTimeout(() => {
        ScrollStart();
    }, 4800);

    function ScrollStart() {
        const random = gsap.utils.random(allEl)

        locoScroll.scrollTo(random, {
            offset: 50
        });

        gsap.fromTo(".progress-line-fill", {
            scaleX: 0,
        },
        {
            scaleX: 1,
            duration: 3.5,
            ease: "none",
        });
    }

    function ScrollRand() {
        setInterval(() => {
            ScrollStart();
        }, 3500);
    }
}